import Scenes from "../Scenes";
import Background from "../../General/Background";
import Image from "../../General/Image";
import Text from "../../General/Text";
import HtmlElement from "../../General/HtmlElement";


export default class WinScene extends Scenes {
  constructor(props) {
    super({...props, key: 'WinScene'});
  }

  preload() {
    const data = this.data.get();
    this.db.addWinner({user_id: data.id});
    [
      {
        name: 'background',
        entity: new Background(this, 'background', 'assets/images/background-375x667px.jpg', {
          x: 187.5,
          y: 333.5,
        })
      },
      {
        name: 'logoOverlay',
        entity: new Image(this, 'logoOverlay', 'assets/images/logo-overlay.png', {
          x: 187.5,
          y: 123,
        })
      },
      {
        name: 'title',
        entity: new Image(this, 'title', 'assets/images/title-230x70px.png', {
          x: 187.5,
          y: 171,
        })
      },
      {
        name: 'congrats',
        entity: new Text(this, ['FELICITATIONS'], {
          x: 41,
          y: 360,
          fontSize: 30,
        })
      },
      {
        name: 'desc',
        entity: new Text(this, ['Vous avez gagné !'], {
          x: 41,
          y: 400,
          fontSize: 23,
        })
      },
      {
        name: 'desc2',
        entity: new Text(this, ["(Et êtes sélectionnés pour le tirage", "au sort)"], {
          x: 41,
          y: 430,
          fontSize: 17,
        })
      },
      {
        name: 'desc3',
        entity: new Text(this, ["Nous revenons bientôt vers vous..."], {
          x: 41,
          y: 470,
          fontSize: 17,
        })
      },
      {
        name: 'visit',
        entity: new HtmlElement(this, 'visit', 'assets/html/visit.html', {
          x: 187.5,
          y: 570,
        })
      },
    ].map(value => this.addEntity(value));
  }

  create() {
    this.createEntities();
    this.cameras.main.fadeIn(1000, 0, 0, 0);
  }
}