import Entity from "./Entity";

export default class HtmlElement extends Entity {
  _path;
  _name;
  _options;

  constructor(props, name, path, options) {
    super(props);
    this._path = path;
    this._name = name;
    this._options = options;
    this._context.load.html(this._name, this._path);
  }

  htmlElementCreate() {
    const {x, y} = (this._options && this._options.x !== undefined && this._options.y !== undefined) ? this._options :  {x: 0, y: 0};

    this._entity = this._context.add.dom(x, y).createFromCache(this._name);
  }

  create() {
    this.htmlElementCreate();
  }
}