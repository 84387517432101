import Entity from "./Entity";

export default class Button extends Entity {
  _callback;
  _text;
  _options;

  constructor(props, text, options) {
    super(props);
    this._text = text;
    this._options = options;
    const { callback } = options;
    this._callback = callback;
  }
  
  
  create() {
    const {x, y} = (this._options && this._options.x !== undefined && this._options.y !== undefined) ? this._options :  {x: 0, y: 0};
    const {width, height} = (this._options && this._options.width !== undefined && this._options.height !== undefined) ? this._options :  {width: 230, height: 70};
    const {textRatio} = (this._options && this._options.textRatio) ? this._options : {textRatio: 5.7}

    this._background = this._context.add.rectangle(x, y, width, height, 0xF7BF08);
    this._background.setStrokeStyle(4, 0x1F3765);
    this._entity = this._context.add.text(x - (this._text.length * textRatio), y - 10, this._text, {
      align: 'center',
      color: '#1F3765',
      stroke: '#1F3765',
      strokeThickness: 1,
      fontSize: 20,
      fontFamily: 'Roboto'
    });
    this._background.setInteractive().on('pointerdown', this._callback);
  }

  remove() {
    this._entity.destroy();
    this._background.destroy();
  }
}