import Entity from "../../General/Entity";

export default class SingleRoller extends Entity {
  _background;
  _options;
  _name;
  _actualIcon = 'plane';
  _isRolling = false;
  
  constructor(props, name, options) {
    super(props);
    this._options = options;
    this._name = name;
  }

  setActualIcon(value) { this._actualIcon = value }
  
  setIsRolling(value) { this._isRolling = value }
  getIsRolling() { return this._isRolling }

  create() {
    const {x, y} = (this._options && this._options.x !== undefined && this._options.y !== undefined) ? this._options :  {x: 0, y: 0};

    this._background = this._context.add.rectangle(x, y, 80, 80, 0xffffff);
    this._background.setStrokeStyle(4, 0x1F3765);
    this._entity = this._context.add.image(x, y, this._actualIcon);
  }

  update() {
    this._entity.setTexture(this._actualIcon);
  }
}