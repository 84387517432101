import './App.css';
import React, {useRef, useEffect} from "react";
import Phaser from 'phaser';

import HomeScene from './Game/Scenes/HomeScene';
import GameScene from './Game/Scenes/GameScene';
import RegistrationScene from './Game/Scenes/RegistrationScene';
import WinScene from './Game/Scenes/WinScene';
import LoseScene from './Game/Scenes/LoseScene';
import DataPlugin from './Game/Plugin/DataPlugin';
import DatabasePlugin from './Game/Plugin/DatabasePlugin';
import OopsScene from './Game/Scenes/OopsScene/OopsScene';

function App() {
  const game = useRef();

  useEffect(() => {
    if (!game.current) {
      game.current = new Phaser.Game({
        type: Phaser.AUTO,
        width: 375,
        height: 667,
        scene: [
          HomeScene,
          RegistrationScene,
          GameScene,
          WinScene,
          LoseScene,
          OopsScene
        ],
        dom: {
          createContainer: true
        },
        plugins: {
          global: [
            {key: 'DataPlugin', plugin: DataPlugin, start: false, mapping: 'data'},
            {key: 'DatabasePlugin', plugin: DatabasePlugin, start: false, mapping: 'db'}
          ]
        },
        parent: 'game-content',
      });
    }
  }, [game]);

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F7BF0A',
    }}>
      <div id="game-content" style={{
        padding: '5px',
        width: '375',
        height: '667',
      }}/>
      <p style={{
        padding: '5px',
        margin: '10px',
        lineHeight: '20px',
        fontWeight: 'bold',
        textAlign: 'center',
      }}><a href="mentionslegales.html" target="_blank">Mentions légales</a></p>
    </div>
  );
}

export default App;
