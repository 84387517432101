import Phaser from "phaser";
import Scenes from "../Scenes";
import Background from "../../General/Background";
import Image from "../../General/Image";
import Text from "../../General/Text";
import Button from "../../General/Button";
import GameScene from "../GameScene";


export default class LoseScene extends Scenes {
  constructor(props) {
    super({...props, key: 'LoseScene'});
  }

  preload() {
    [
      {
        name: 'background',
        entity: new Background(this, 'background', 'assets/images/background-375x667px.jpg', {
          x: 187.5,
          y: 333.5,
        })
      },
      {
        name: 'logoOverlay',
        entity: new Image(this, 'logoOverlay', 'assets/images/logo-overlay.png', {
          x: 187.5,
          y: 123,
        })
      },
      {
        name: 'title',
        entity: new Image(this, 'title', 'assets/images/title-230x70px.png', {
          x: 187.5,
          y: 171,
        })
      },
      {
        name: 'sorry',
        entity: new Text(this, ['PERDU !'], {
          x: 41,
          y: 360,
          fontSize: 30,
        })
      },
      {
        name: 'desc',
        entity: new Text(this, ['Retentez votre chance !'], {
          x: 41,
          y: 400,
          fontSize: 23,
        })
      },
      {
        name: 'visit',
        entity: new Button(this, 'RÉESSAYER', {
          x: 187.5,
          y: 520,
          callback: () => {
            this.cameras.main.fadeOut(1000, 0, 0, 0)
            this.scene.remove('GameScene');
            this.scene.add('GameScene', GameScene);

            this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, () => {
              this.scene.start('GameScene');
            });
          },
          textRatio: 6.5
        })
      },
    ].map(value => this.addEntity(value));
  }

  create() {
    this.createEntities();
    this.cameras.main.fadeIn(1000, 0, 0, 0);
  }
}