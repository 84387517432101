import Scenes from "../Scenes";
import Background from "../../General/Background";
import Image from "../../General/Image";
import Text from "../../General/Text";
import HtmlElement from "../../General/HtmlElement";


export default class OopsScene extends Scenes {
  constructor(props) {
    super({...props, key: 'OopsScene'});
  }

  preload() {
    [
      {
        name: 'background',
        entity: new Background(this, 'background', 'assets/images/background-375x667px.jpg', {
          x: 187.5,
          y: 333.5,
        })
      },
      {
        name: 'logoOverlay',
        entity: new Image(this, 'logoOverlay', 'assets/images/logo-overlay.png', {
          x: 187.5,
          y: 123,
        })
      },
      {
        name: 'title',
        entity: new Image(this, 'title', 'assets/images/title-230x70px.png', {
          x: 187.5,
          y: 171,
        })
      },
      {
        name: 'congrats',
        entity: new Text(this, ['OOPS!'], {
          x: 41,
          y: 360,
          fontSize: 30,
        })
      },
      {
        name: 'desc',
        entity: new Text(this, ["Il semblerait que", "vous soyez déjà inscrit.e.s", "ou que vous ne réunissiez pas", "les conditions pour le faire"], {
          x: 41,
          y: 400,
          fontSize: 19,
        })
      },
      {
        name: 'visit',
        entity: new HtmlElement(this, 'visit', 'assets/html/visit.html', {
          x: 187.5,
          y: 570,
        })
      },
    ].map(value => this.addEntity(value));
  }

  create() {
    this.createEntities();
    this.cameras.main.fadeIn(1000, 0, 0, 0);
  }
}