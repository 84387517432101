import Entity from "./Entity";

export default class Image extends Entity {
  _name;
  _path;
  _options;

  constructor(props, name, path, options) {
    super(props);
    this._name = name;
    this._path = path;
    this._options = options;
    this._context.load.image(this._name, this._path);
  }

  imageCreate() {
    const {x, y} = (this._options && this._options.x !== undefined && this._options.y !== undefined) ? this._options :  {x: 0, y: 0};
    this._entity = this._context.add.image(x, y, this._name);
  }

  create() {
    this.imageCreate();
  }

  update() {
    
  }
}