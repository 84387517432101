import Phaser from "phaser";
import Scenes from "../Scenes";
import Background from "../../General/Background";
import Image from "../../General/Image";
import Text from "../../General/Text";
import Button from "../../General/Button";
import RollerGroup from "./RollerGroup";


export default class GameScene extends Scenes {
  constructor(props) {
    super({...props, key: 'GameScene'});
  }

  preload() {
    [
      {
        name: 'background',
        entity: new Background(this, 'background', 'assets/images/background-375x667px.jpg', {
          x: 187.5,
          y: 333.5,
        })
      },
      {
        name: 'logoOverlay',
        entity: new Image(this, 'logoOverlay', 'assets/images/logo-overlay.png', {
          x: 187.5,
          y: 123,
        })
      },
      {
        name: 'title',
        entity: new Image(this, 'title', 'assets/images/title-230x70px.png', {
          x: 187.5,
          y: 171,
        })
      },
      {
        name: 'desc',
        entity: new Text(this, ['De juillet à décembre,', 'tentez de gagner tous les mois', '2 billets aller/retour', 'pour Paris !', '', "Trois avions et c'est gagné !"], {
          x: 41,
          y: 213,
          fontSize: 17,
        })
      },

      {
        name: 'rollers',
        entity: new RollerGroup(this)
      },
      {
        name: 'playButton',
        entity: new Button(this, 'LANCER', {
          x: 187.5,
          y: 520,
          callback: () => {
            const rollers = this._entities.find(({name}) => name === 'rollers').entity;

            this.db.fetchWinners().then(({data}) => {
              rollers.launchRolling(true);
              this.removeEntity('playButton');
              this.time.delayedCall(3000, rollers.stopRolling, [], rollers);
              this.time.delayedCall(10000, () => {
                const result = rollers.getRollersPosition();
                this.cameras.main.fadeOut(1000, 0, 0, 0);
                this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, () => {
                  this.scene.start((result[0] === 0 && result[1] === 0 && result[2] === 0) ? 'WinScene' : 'LoseScene');
                })
              }, [], rollers);
            })
          },
          textRatio: 6.5
        })
      },
    ].map(value => this.addEntity(value));
  }
  
  create() {
    this.createEntities();
    this.cameras.main.fadeIn(1000, 0, 0, 0);
  }
}