import Phaser from "phaser";
import Scenes from "../Scenes";
import Background from "../../General/Background";
import Image from "../../General/Image";
import TextInput from "./TextInput";


export default class RegistrationScene extends Scenes {
  constructor(props) {
    super({...props, key: 'RegistrationScene'});
  }

  preload() {
    [
      {
        name: 'background',
        entity: new Background(this, 'background', 'assets/images/background-375x667px.jpg', {
          x: 187.5,
          y: 333.5,
        })
      },
      {
        name: 'logoOverlay',
        entity: new Image(this, 'logoOverlay', 'assets/images/logo-overlay.png', {
          x: 187.5,
          y: 123,
        })
      },
      {
        name: 'title',
        entity: new Image(this, 'title', 'assets/images/title-230x70px.png', {
          x: 187.5,
          y: 171,
        })
      },
      {
        name: 'textInput',
        entity: new TextInput(this, 'title', 'assets/html/registrationform.html', {
          x: 187.5,
          y: 420,
          onClick: ({target}) => {
            const { entity } = this.getSingleEntity('textInput');
            
            if (target.name === 'loginButton') {
              target.disabled = true;
              const inputName = entity.get().getChildByName('name');
              const inputBirth = entity.get().getChildByName('birth');
              const inputEmail = entity.get().getChildByName('eaddress');
              const inputPhone = entity.get().getChildByName('phone');
              const inputStreet = entity.get().getChildByName('street');
              const inputCity = entity.get().getChildByName('city');
              const inputZip = entity.get().getChildByName('zip');
              const inputAcceptation = entity.get().getChildByName('accept');
              const inputAcceptationMail = entity.get().getChildByName('acceptmail');
              const inputAcceptationSMS = entity.get().getChildByName('acceptsms');
              const inputFavorite = entity.get().getChildByName('favorite');
              
              const birthTest = new RegExp(/(\d{2})\/(\d{2})\/(\d{4})/g);
              const phoneTest = new RegExp(/(\d{10})/g);
              const mailTest = new RegExp(/(?:^|\s)[\w!#$%&'*+/=?^`{|}~-](\.?[\w!#$%&'*+/=?^`{|}~-]+)*@\w+[.-]?\w*\.[a-zA-Z]{2,3}/g);
              
              const userData = {
                name: inputName.value,
                birth: inputBirth.value,
                mail: inputEmail.value,
                phone: inputPhone.value,
                adresse: inputStreet.value,
                state: inputCity.value,
                zip: inputZip.value,
                favorite_center: inputFavorite.value,
                acceptation_mail: (inputAcceptationMail.checked) ? 1 : 0,
                acceptation_sms: (inputAcceptationSMS.checked) ? 1 : 0
              }
              this.data.set(userData);
              
              if (
                userData.name && userData.name.length !== 0
                && userData.birth && userData.birth.length !== 0 && birthTest.test(userData.birth)
                && userData.mail && userData.mail.length !== 0 && mailTest.test(userData.mail)
                && userData.phone && userData.phone.length !== 0 && phoneTest.test(userData.phone)
                && userData.favorite_center && userData.favorite_center.length !== 0
                && inputAcceptation.checked
                ) {
                  this.db.fetchUsers().then(({data}) => {
                    const user = data.find(value => value.mail.toLowerCase() === userData.mail.toLowerCase() || value.phone === userData.phone);
                    const age = ((dateString) => {
                      const split = dateString.split('/');
                      const today = new Date();
                      const birthDate = new Date(`${split[2]}-${split[1]}-${split[0]}`);
                      let age = today.getFullYear() - birthDate.getFullYear();
                      const m = today.getMonth() - birthDate.getMonth();
                      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
                      {
                          age--;
                      }
                      return age;
                    })(userData.birth);

                    if (user || (age && age < 18)) {
                      this.cameras.main.fadeOut(1000, 0, 0, 0)
                      this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, () => {
                        this.scene.start('OopsScene');
                      })
                    } else {
                      this.db.addUser(userData).then(({data}) => {
                        this.data.set({
                          ...this.data.get(),
                          id: data
                        })
                        this.cameras.main.fadeOut(1000, 0, 0, 0)
                        this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, () => {
                          this.scene.start('GameScene');
                        })
                      })
                    }
                  })
              } else {
                target.disabled = false;
                if (!inputAcceptation.checked) {
                  alert("Merci d'accepter le règlement du jeu.")
                } else {
                  alert('Les champs renseignés sont incorrects ou incomplets.')
                }
              }
            }
          }
        })
      },
    ].map(value => this.addEntity(value));
  }

  create() {
    this.createEntities();
    this.cameras.main.fadeIn(1000, 0, 0, 0);
  }
}