import Phaser from "phaser";

export default class Scenes extends Phaser.Scene {
  _entities = []

  getEntities() { return this._entities }
  getSingleEntity(name) { return this._entities.find(entity => entity.name === name)}
  
  addEntity(entity) { this._entities.push(entity) }
  removeEntity(name) {
    const toRemove = this._entities.find((entity) => entity.name === name).entity;
    toRemove.remove();
    this._entities = this._entities.filter(entity => entity.name !== name)
  
  }

  preloadEntities() { this._entities.map(entity => entity.preload()) }
  createEntities() {
    this._entities.map(value => {
      const { entity } = value;
      if (entity.create)
        return entity.create()
      return null;
    })
  }
  updateEntities() {
    this._entities.map(value => {
      const { entity } = value;
      if (entity.update)
        return entity.update()
      return null;
    })
  }

  create() { this.createEntities() }

  update() { this.updateEntities() }
}