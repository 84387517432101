import HtmlElement from "../../General/HtmlElement";

export default class TextInput extends HtmlElement {
  _onClickCallback;

  constructor(props, name, path, options) {
    super(props, name, path, options);
    if (options.onClick)
      this._onClickCallback = options.onClick;
  }
  

  create() {
    this.htmlElementCreate();
    this._entity.addListener('click');
    this._entity.on('click', this._onClickCallback);
  }
}
