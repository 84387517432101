export default class Entity {
  _context
  _entity

  constructor(context) {
    this._context = context
  }

  get() { return this._entity }
  set(entity) {
    this._entity = entity
  }

  remove() {
    this._entity.destroy();
  }

  create() {}

  update() {}
}