import Entity from "./Entity";

export default class Text extends Entity {
  _textValue;
  _options;

  constructor(props, text, options) {
    super(props);
    this._textValue = text;
    this._options = options
  }
  
  textCreate() {
    const { x, y } = (this._options && this._options.x !== undefined && this._options.y !== undefined) ? this._options :  {x: 0, y: 0};
    const { fontSize } = (this._options && this._options.fontSize !== undefined) ? this._options :  {fontSize: 16};
    this._entity = this._context.add.text(x, y, this._textValue, {
      align: 'center',
      fixedWidth: '290',
      fontFamily: 'Roboto',
      fontSize: fontSize
    });
  }

  create() {
    this.textCreate();
  }
}