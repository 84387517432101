import Entity from "../../General/Entity";
import SingleRoller from "./SingleRoller";

export default class RollerGroup extends Entity {
  _rollers = [];
  _canWin = true;
  _rollersType = [
    'plane',
    'ananas',
    'bag',
    'eiffel',
    'sun',
  ];
  _rollersPosition = [0, 0, 0]

  constructor(props) {
    super(props);

    this._rollersType.map(type => this._context.load.image(type, `assets/images/rollers/${type}.png`));
    this._rollers = [
      {
        x: 100,
        y: 403,
      },
      {
        x: 187.5,
        y: 403,
      },
      {
        x: 275,
        y: 403,
      },
    ].map(({x, y}, i) => {
      return {
        id: i,
        entity: new SingleRoller(props, `roller${i}`, {x, y})
      }
    })
  }

  create() {
    this._rollers.map(({entity}) => entity.create());
  }

  launchRolling(canWin) {
    this._canWin = canWin;
    this._rollers.map(({entity}) => entity.setIsRolling(true));
  }

  stopRolling() {
    const singleStop = (i) => {
      const {entity} = this._rollers[i];
      if (i < 2)
        this._context.time.delayedCall(3000, singleStop, [i + 1], this);
        entity.setIsRolling(false);
    }
    singleStop(0);
  }

  getRollersPosition() { return this._rollersPosition }

  update() {
    for (let i = 0; i < 3; i++) {
      const {entity} = this._rollers[i];
      const min = Math.ceil(0);
      const max = Math.floor(5);

      if (entity.getIsRolling()) {
        const random = Math.floor(Math.random() * (max - min)) + min;
        
        this._rollersPosition[i] = random;
        entity.setActualIcon(this._rollersType[this._rollersPosition[i]]);
      }
    }
    this._rollers.map(({entity}) => entity.update());
  }
}