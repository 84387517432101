import axios from "axios";
import Phaser from "phaser";

function getQueryString(data = {}) {
  return Object.entries(data)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export default class DatabasePlugin extends Phaser.Plugins.BasePlugin {
  _userData;
  _url = 'api-jeufacebook-BxFTJ67S.lesgrandscentres.re'
  
  set(value) { this._userData = value };
  get() { return this._userData };

  async fetchUsers() {
    return await axios({
      method: 'get',
      url: 'https://' + this._url + '/index.php/user/list'
    });
  }

  async fetchWinners() {
    return await axios({
      method: 'get',
      url: 'https://' + this._url + '/index.php/winner/list'
    })
  }

  async addUser(data) {
    return await axios({
      method: 'post',
      headers: {
        "Content-Type" : "application/x-www-form-urlencoded"
      },
      url: 'https://' + this._url + '/index.php/user/add',
      transformRequest: getQueryString,
      data: data
    })
  }

  addWinner(data) {
    return axios({
      method: 'post',
      headers: {
        "Content-Type" : "application/x-www-form-urlencoded"
      },
      url: 'https://' + this._url + '/index.php/winner/add',
      transformRequest: getQueryString,
      data: data
    })
  }
}