import Phaser from "phaser";
import Background from "../../General/Background";
import Button from "../../General/Button";
import HtmlElement from "../../General/HtmlElement";
import Image from "../../General/Image";
import Text from "../../General/Text";
import Scenes from "../Scenes";

export default class HomeScene extends Scenes {
  constructor(props) {
    super({...props, key: 'HomeScene'});
  }
  
  preload() {
    [
      {
        name: 'background',
        entity: new Background(this, 'background', 'assets/images/background-375x667px.jpg', {
          x: 187.5,
          y: 333.5,
        })
      },
      {
        name: 'logoOverlay',
        entity: new Image(this, 'logoOverlay', 'assets/images/logo-overlay.png', {
          x: 187.5,
          y: 123,
        })
      },
      {
        name: 'title',
        entity: new Image(this, 'title', 'assets/images/title-230x70px.png', {
          x: 187.5,
          y: 171,
        })
      },
      {
        name: 'description',
        entity: new Text(this, [
          'De juillet à décembre,',
          'tentez de gagner tous les mois',
          '2 billets aller/retour',
          'pour Paris !',
          // '',
          // 'Lancez le Bandit manchot',
          // 'pour tenter votre chance !',
          // '',
          // "3              et c'est gagné !",
        ], {
          x: 41,
          y: 270,
          fontSize: 18
        })
      },
      {
        name: 'description',
        entity: new Text(this, [
          "3        et c'est gagné !", ''
        ], {
          x: 41,
          y: 400,
          fontSize: 28,
        })
      },
      {
        name: 'plane-icone',
        entity: new Image(this, 'homePagePlane', 'assets/images/plane-40x40px.png', {
          x: 100,
          y: 414,
        })
      },
      {
        name: 'playButton',
        entity: new Button(this, 'PARTICIPER', {
          x: 187.5,
          y: 520,
          callback: () => {
            this.cameras.main.fadeOut(1000, 0, 0, 0)
            this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, () => {
              this.scene.start('RegistrationScene');
            })
          },
          textRatio: 5.7
        })
      },
      {
        name: 'reglement',
        entity: new HtmlElement(this, 'reglement', 'assets/html/reglement.html', {
          x: 187.5,
          y: 571,
        })
      },
    ].map(value => this.addEntity(value));
  }
}